/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shrikhand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  background-color: #f7f8fa;
}

.logo {
  font-size: 15px;
  font-weight: bold;
  color: black;
  font-family: "Fredoka", sans-serif;
}

/* Add this CSS to your stylesheet or in a <style> tag in the HTML head */
#tawkiframe {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  margin-left: 10px !important; /* Optional, for some spacing from the corner */
  margin-bottom: 10px !important; /* Optional, for some spacing from the corner */
}





html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
